<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Search Purchase Order"
                v-model="innerValue.searchValue"
                @keyup.enter="onFilterClick()">
            </div>
            <div class="help">
              Search by
              <ul>
                <li>Order No.</li>
                <li>Quote No.</li>
                <li>PartsCheck Order No.</li>
                <li>Vendor</li>
              </ul>

            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>
<script>
import AppSideMenu from '@/components/AppSideMenu'
import { mask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PurchaseOrderListSideMenu',
  components: {
    AppSideMenu
  },
  directives: {
    mask
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      stateList: null,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function(newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    onFilterClick() {
      this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
    }
  }
}
</script>
